<template>
  <!--<secured-area>
    <left-aside-menu menu-title="Configuration">
      <side-bar-menu-link :to="{name:'Configuration/Index'}">Général</side-bar-menu-link>
    </left-aside-menu>
  </secured-area>-->
            <router-view></router-view>
</template>

<script>
/*import SideBarMenuLink from '@/components/Panels/SideBarMenuLink.vue';
import SecuredArea from '@/components/Security/SecuredArea.vue';
import LeftAsideMenu from '@/components/PageTemplates/LeftAsideMenu copy.vue';
*/
export default{
/*  components: { SideBarMenuLink, SecuredArea, LeftAsideMenu },
  mounted(){
    this.$hideLoader();
  }*/
}
</script>

